import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import imageHeaderBanner from "./../../assets/images/global/imageHeaderBannerReservation.png";
import DescriptionSplit from "../../components/Description/description-split";
import Select from "../../components/Inputs/select";
import Hr from "../../components/Style/hr";
import H2 from "../../components/Titles/h2";
import H3 from "../../components/Titles/h3";
import H4 from "../../components/Titles/h4";
import H5 from "../../components/Titles/h5";
import H6 from "../../components/Titles/h6";
import Button from "../../components/Buttons/button";
import Api from "../../services/api.service";
import AccommodationSelected from "./components/accommodation-selected";
import AccommodationDisplayed from "./components/accommodation-displayed";
import ListOfServices from "./components/list-of-services";
import PersonnalInfoForm from "../../components/Forms/personnal-info-form";
import BackgroundImage from "../../components/Banner/background-header";
import Textarea from "../../components/Inputs/textarea";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Helmet } from "react-helmet";
import lodash from "lodash";
import Validator from "../../services/validator.service";
import Helper from "../../services/helper.service";
import DateFieldInput from "../../components/Inputs/date-field";
import * as _ from "lodash";

export default function Reservation() {
    const { t, i18n } = useTranslation();
    const touristTax = 3.3;

    const getInitialState = () => {
        const stayDatas = Api.getStayDatas();
        const storedStayDates = localStorage.getItem('stayDates');
        const storedNbAdults = localStorage.getItem('nbAdults');
        const storedNbChilds = localStorage.getItem('nbChilds');
        const storedComment = localStorage.getItem('comment');
        const storedAccommodationSelected = localStorage.getItem('accommodationSelected');
        const storedServicesSelected = localStorage.getItem('servicesSelected');
        const storedPersonnalInfoFormData = localStorage.getItem('personnalInfoFormData');

        return {
            stayDates: storedStayDates ? JSON.parse(storedStayDates) : stayDatas.stayDates || {
                startDate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
                endDate: moment(new Date()).add(3, 'days').format('YYYY-MM-DD')
            },
            nbAdults: Number(storedNbAdults) || stayDatas.nbPersons || 2,
            nbChilds: Number(storedNbChilds) || 0,
            comment: storedComment || "",
            accommodationSelected: storedAccommodationSelected ? JSON.parse(storedAccommodationSelected) : (!stayDatas.nbPersons || stayDatas.nbPersons < 11 ? ['annex'] : stayDatas.nbPersons < 16 ? ['home'] : ['home', 'annex']),
            servicesSelected: storedServicesSelected ? JSON.parse(storedServicesSelected) : [],
            personnalInfoFormData: storedPersonnalInfoFormData ? JSON.parse(storedPersonnalInfoFormData) : {
                lastname: "",
                firstname: "",
                email: "",
                phone: ""
            }
        };
    };

    const [stayDates, setStayDates] = useState(getInitialState().stayDates);
    const [nbAdults, setNbAdults] = useState(getInitialState().nbAdults);
    const [nbChilds, setNbChilds] = useState(getInitialState().nbChilds);
    const [comment, setComment] = useState(getInitialState().comment);
    const [displayedAccommodation] = useState(['home', 'annex']);
    const [accommodationPrice, setAccommodationPrice] = useState(0);
    const [discountLongAccommodation, setDiscountLongAccommodation] = useState(0);
    const [accommodationSelected, setAccommodationSelected] = useState(getInitialState().accommodationSelected);
    const [servicesSelected, setServicesSelected] = useState(getInitialState().servicesSelected);
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState(getInitialState().personnalInfoFormData);
    const [blockedDates, setBlockedDates] = useState([]);
    const [prices, setPrices] = useState({
        annex: { price: 0, listOfDiscountForLongReservation: [] },
        home: { price: 0, listOfDiscountForLongReservation: [] },
        domain: { price: 0, listOfDiscountForLongReservation: [] }
    });
    const [services, setServices] = useState([]);
    const [displayFormContactDetails, setDisplayFormContactDetails] = useState(false);
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    const maxPeopleDomain = 22;
    const maxPeopleHome = 12;
    const maxPeopleAnnex = 10;

    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };

    const getNbDaysStay = useCallback(() => {
        const days = moment(stayDates.endDate).diff(stayDates.startDate, 'days');
        return lodash.isNaN(days) ? 0 : days;
    }, [stayDates]);

    const getPricesForDates = useCallback(async () => {
        let typeOfAccommodation = "";

        if (accommodationSelected.length > 1) {
            typeOfAccommodation = "domain";

        } else {
            typeOfAccommodation = accommodationSelected[0];
        }

        console.log(stayDates.startDate, stayDates.endDate);

        const response = await Api.getPricesForDates(stayDates.startDate, stayDates.endDate, typeOfAccommodation);
        const res = response.data;
        console.log(res);

        if (_.get(response, 'data.status', false)) {
            const totalAccommodationPrice = _.get(res, 'data.accommodationPrice');
            if (!totalAccommodationPrice) return;

            const dailyAccommodationPrice = totalAccommodationPrice / getNbDaysStay();
            if (!dailyAccommodationPrice) return;

            setAccommodationPrice(dailyAccommodationPrice);
            setDiscountLongAccommodation(_.get(res, 'data.discountLongAccommodation', 0));
        }
    }, [stayDates, accommodationSelected, getNbDaysStay]);

    // Fetch prices and services data on mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Api.getPrices();
                const res = response.data;
                if (res.status) {
                    setPrices(res.data);
                } else {
                    console.log("Error:", res.error);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }

            try {
                const response = await Api.getServices();
                const res = response.data;
                if (res.status) {
                    setServices(res.data);
                } else {
                    console.log("Error:", res.error);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }

            const blockedDates = Api.getBlockedDatesStored();
            setBlockedDates(blockedDates);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (stayDates) {
            getPricesForDates();
        }
    }, [stayDates, accommodationSelected, getPricesForDates]);

    // Store data in localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('stayDates', JSON.stringify(stayDates));
    }, [stayDates]);

    useEffect(() => {
        localStorage.setItem('nbAdults', nbAdults);
    }, [nbAdults]);

    useEffect(() => {
        localStorage.setItem('nbChilds', nbChilds);
    }, [nbChilds]);

    useEffect(() => {
        localStorage.setItem('comment', comment);
    }, [comment]);

    useEffect(() => {
        localStorage.setItem('accommodationSelected', JSON.stringify(accommodationSelected));
    }, [accommodationSelected]);

    useEffect(() => {
        localStorage.setItem('servicesSelected', JSON.stringify(servicesSelected));
    }, [servicesSelected]);

    useEffect(() => {
        localStorage.setItem('personnalInfoFormData', JSON.stringify(personnalInfoFormData));
    }, [personnalInfoFormData]);

    const numberOptions = (labelKey, maxNumber = 25) => {
        return Array.from({ length: maxNumber + 1 }, (_, index) => {
            if (labelKey === "adult" && index === 0) {
                return null; // Exclude the option with value 0
            }
            return {
                value: `${index}`,
                label: `${index} ${t(`formInputs.${labelKey}`)}${index > 1 ? 's' : ''}`,
            };
        }).filter(option => option !== null);
    };

    const handleDateChange = (e) => {
        setStayDates(e.date);
    };

    const getPriceAccommodation = () => {
        let price = 0;
        let typeOfAccommodation = "";

        if (accommodationSelected.length > 1) {
            typeOfAccommodation = "domain";

        } else {
            typeOfAccommodation = accommodationSelected[0];
        }

        price = prices[typeOfAccommodation].price;

        return displayNumberWithSpace(price);
    }

    const getSubTotalAccommodation = () => {
        const days = getNbDaysStay();
        const accommodationPriceWithoutSpaces = String(getPriceAccommodation()).replace(/\s/g, '');
        const accommodationPriceFloat = parseFloat(accommodationPriceWithoutSpaces);

        const amountStayCleaningFees = getPriceEndOfStayCleaningFees();
        const touristTax = getPriceTouristTax();
        const total = (days * accommodationPrice || accommodationPriceFloat) - discountLongAccommodation + amountStayCleaningFees + touristTax;

        return displayNumberWithSpace(total);
    }

    const displayNumberWithSpace = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    // const getAmountLongAccommodation = () => {
    //     const nbDays = getNbDaysStay();
    //     let typeOfAccommodation = "";

    //     if (accommodationSelected.length > 1) {
    //         typeOfAccommodation = "domain";

    //     } else {
    //         typeOfAccommodation = accommodationSelected[0];
    //     }

    //     let amountOfDiscount = 0;
    //     for (const discount of prices[typeOfAccommodation].listOfDiscountForLongReservation) {
    //         if (nbDays > discount.numberOfDays) {
    //             amountOfDiscount = (discount.amountOfReduction / 100) * prices[typeOfAccommodation].price * nbDays; // Calculate the discount amount
    //         }
    //     }
    //     return amountOfDiscount;
    // }

    const getPriceEndOfStayCleaningFees = () => {
        let typeOfAccommodation = "";

        if (accommodationSelected.length > 1) {
            typeOfAccommodation = "domain";

        } else {
            typeOfAccommodation = accommodationSelected[0];
        }

        return prices[typeOfAccommodation].cleaning;
    }

    const getPriceTouristTax = () => {
        const nbDays = getNbDaysStay();

        return Number((nbAdults * touristTax * nbDays).toFixed(2));
    };

    const getTotal = () => {
        const accommodation = getSubTotalAccommodation().replace(/\s/g, '');
        const services = getSubTotalServices().replace(/\s/g, '');
        return displayNumberWithSpace(Number(accommodation) + Number(services));
    }

    const handleSelectChangeAdults = (event) => {
        const selectedAdults = Number(event.target.value);
        const totalPersons = selectedAdults + nbChilds;

        if (totalPersons > 25) {
            setNbChilds(25 - selectedAdults);
        }

        setNbAdults(selectedAdults);
    };

    const handleSelectChangeChilds = (event) => {
        const selectedChildren = Number(event.target.value);
        const totalPersons = nbAdults + selectedChildren;

        if (totalPersons > 25) {
            setNbAdults(25 - selectedChildren);
        }

        setNbChilds(selectedChildren);
    };

    const onSelectAccommodation = (value) => {
        setAccommodationSelected(value);

        const totalPeople = nbAdults + nbChilds;

        if (value.length === 1) {
            if (value[0] === "home") {
                if (totalPeople > maxPeopleHome) {
                    if (nbAdults > maxPeopleHome) {
                        setNbAdults(maxPeopleHome);
                        setNbChilds(0);

                    } else {
                        setNbChilds(maxPeopleHome - nbAdults);
                    }

                    return Helper.toast(t('errors.tooManyPeopleHome'), "info");
                }
            } else if (value[0] === "annex") {
                if (totalPeople > maxPeopleAnnex) {
                    if (nbAdults > maxPeopleAnnex) {
                        setNbAdults(maxPeopleAnnex);
                        setNbChilds(0);

                    } else {
                        setNbChilds(maxPeopleAnnex - nbAdults);
                    }

                    return Helper.toast(t('errors.tooManyPeopleAnnex'), "info");
                }
            }
        }
    }

    const getNbMaxPeople = () => {
        if (accommodationSelected.length === 2) {
            return maxPeopleDomain;

        } else if (accommodationSelected.length === 1) {
            if (accommodationSelected[0] === "home") {
                return maxPeopleHome;

            } else if (accommodationSelected[0] === "annex") {
                return maxPeopleAnnex;
            }
        }

        return 0;
    }

    const onSelectService = (value) => {
        setServicesSelected(value);
    }

    const getPriceService = (service) => {
        let total = 0;
        if (service.isPriceDefined) {
            if (service.isPricePerPersonOrForEveryone === "perPerson") {
                if (service.priceMustBePaidEach === "day") {
                    total += service.price * (Number(nbAdults) + Number(nbChilds)) * getNbDaysStay();
                } else {
                    total += service.price * (Number(nbAdults) + Number(nbChilds));
                }
            } else {
                if (service.priceMustBePaidEach === "day") {
                    total += service.price * service.quantity * getNbDaysStay();
                } else {
                    total += service.price * service.quantity;
                }
            }
            return displayNumberWithSpace(total);
        }
        return;
    }

    const getSubTotalServices = () => {
        let totalPrice = 0;
        for (const service of servicesSelected) {
            let price = getPriceService(service);
            if (price) {
                totalPrice += Number(String(getPriceService(service).replace(/\s/g, '')));
            }
        }
        return displayNumberWithSpace(totalPrice);
    }

    const clickDisplayFormContactDetails = () => {
        if (!stayDates.startDate || !stayDates.endDate) {
            return Helper.toast(t('errors.noDate'), "error");

        } else {
            const tomorrow = new Date();
            tomorrow.setHours(0, 0, 0, 0);
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (new Date(stayDates.startDate) < tomorrow || new Date(stayDates.endDate) < tomorrow) {
                return Helper.toast(t('errors.invalidDate'), "error");
            }
        }

        setDisplayFormContactDetails(true);
    }

    async function sendQuotation() {
        if (!stayDates.startDate || !stayDates.endDate) {
            return Helper.toast(t('errors.noDate'), "error");

        } else {
            const tomorrow = new Date();
            tomorrow.setHours(0, 0, 0, 0);
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (new Date(stayDates.startDate) < tomorrow || new Date(stayDates.endDate) < tomorrow) {
                return Helper.toast(t('errors.invalidDate'), "error");
            }

            // Check for overlap with blocked dates
            const stayStart = new Date(stayDates.startDate).getTime();
            const stayEnd = new Date(stayDates.endDate).getTime();

            for (let i = 0; i < blockedDates.length; i++) {
                const blockedStart = blockedDates[i].startDate;
                const blockedEnd = blockedDates[i].endDate;

                // Check if stay dates overlap with any blocked date range
                if (!(stayEnd <= blockedStart || stayStart >= blockedEnd)) {
                    return Helper.toast(t('errors.blockedDates'), "error");
                }
            }
        }

        const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
        setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);

        if (Object.keys(tmpErrorsPersonnalInfo).length === 0) {
            const quotation = {
                dates: {
                    startDate: stayDates.startDate,
                    endDate: stayDates.endDate,
                    creationDate: new Date(),
                    expirationDate: new Date(new Date().setMonth(new Date().getMonth() + 1))
                },
                mainInfos: {
                    status: 'draft',
                    language: i18n.language,
                    numberOfGuests: {
                        adults: nbAdults,
                        childs: nbChilds
                    }
                },
                prices: {
                    accommodationPrice: Number(Number(accommodationPrice * getNbDaysStay()).toFixed(2)),
                    typeOfAccommodation: accommodationSelected.length === 2 ? "domain" : accommodationSelected[0],
                    totalPrice: Number(String(getTotal().replace(/\s/g, ''))),
                    cleaning: Number(getPriceEndOfStayCleaningFees()),
                    discountLongAccommodation: discountLongAccommodation,
                    touristTax: getPriceTouristTax()
                },
                customer: {
                    lastname: personnalInfoFormData.lastname,
                    firstname: personnalInfoFormData.firstname,
                    email: personnalInfoFormData.email,
                    phone: personnalInfoFormData.phone
                },
                guests: [],
                address: {},
                services: servicesSelected.map(service => ({
                    slug: service.slug,
                    name: service.name['fr'],
                    quantity: service.quantity,
                    isPriceDefined: service.isPriceDefined,
                    price: service.isPriceDefined ? Number(String(getPriceService(service).replace(/\s/g, ''))) : 0,
                    VAT: service.VAT,
                    priceMustBePaidEach: service.priceMustBePaidEach
                })),
                history: []
            };

            const response = await Api.saveQuotation(quotation);
            Helper.handleApiResponse(response, t('responses.quotationSend'), t('responses.quotationError'));
            setDisplayFormContactDetails(false);

            const responseEmail = await Api.sendQuotation(response.data.data);
            Helper.handleApiResponse(responseEmail, t('responses.quotationSent'), t('responses.quotationError'));

            if (_.get(response, 'data.status')) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'quotationFormSubmissionSuccess',
                    formType: 'quotation'
                });
            }
        }
    }

    const tenYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    return (
        <div>
            <Helmet>
                <title>{t('reservation.page')}</title>
                <meta name="description" content={t('reservation.description')} />
                <meta name="keywords" content={t('reservation.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} customHeight={"50vh"} />
            </div>

            <div className="relative w-11/12 md:w-3/4 mx-auto mt-20 md:mt-40 flex flex-col md:flex-row gap-6 md:gap-10 mb-10">
                <div className="w-full md:w-3/5 bg-white rounded-3xl py-6 px-4 md:px-8 h-fit shadow-lg">
                    <H2 title={t('reservation.quotation.title')} fontWeight='normal' />
                    <div className="text-xs font-normal mt-2">
                        <DescriptionSplit description={t('reservation.quotation.description')} />
                        <DescriptionSplit description={t('reservation.quotation.additionnalDescription')} className="font-bold" />
                    </div>

                    <div className="my-4 flex flex-col md:flex-row justify-between gap-4">
                        <AccommodationDisplayed accommodation={displayedAccommodation} accommodationSelected={accommodationSelected} prices={prices} onChange={onSelectAccommodation} />
                    </div>

                    <H3 title={t('reservation.quotation.services.title')} fontWeight='normal' />
                    <div className="text-xs font-normal mt-2 mb-6">
                        <DescriptionSplit description={t('reservation.quotation.services.description')} />
                    </div>

                    <ListOfServices services={services} numberOfGuests={nbAdults + nbChilds} onChange={onSelectService} />

                    <div className="mt-8">
                        <H3 title={t('reservation.textarea.title')} fontWeight='normal' />
                        <div className="mt-2">
                            <Textarea
                                placeholder={t('reservation.textarea.placeholder')}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div id="fixed-column" className="w-full md:w-2/5 h-auto flex flex-col">
                    <div className="bg-white rounded-3xl h-full flex flex-col justify-end">
                        <div className="py-6 px-4 md:px-8 rounded-3xl shadow-lg sticky bottom-6">
                            <div className="text-center">
                                <H3 title={t('reservation.details.title')} fontWeight='normal' />
                            </div>

                            <Hr />

                            <div className="flex w-full">
                                <DateFieldInput label='stayDate' value={stayDates} minDate={new Date()} maxDate={tenYearsLater} onChange={handleDateChange} displayLabel={false} singleDate={false} blockedDates={blockedDates} className="rounded-b-none" />
                            </div>
                            <div className="w-full">
                                <Select label="adults" name={t('formInputs.adult')} options={numberOptions("adult", getNbMaxPeople() - nbChilds)} value={nbAdults}
                                    onChange={handleSelectChangeAdults} displayLabel={false} iconDisplayed={faUser} error={null} className="rounded-none" />
                                <Select label="childs" name={t('formInputs.child')} options={numberOptions("child", getNbMaxPeople() - nbAdults)} value={nbChilds}
                                    onChange={handleSelectChangeChilds} displayLabel={false} iconDisplayed={faUser} error={null} className="rounded-t-none" />
                            </div>

                            <div className="w-full mt-6">
                                <AccommodationSelected accommodation={accommodationSelected} />
                            </div>

                            {accommodationSelected.length === 1 &&
                                <div className="w-full mt-6">
                                    <H4 title={t('reservation.exclusivity.title')} />
                                    <div className="shadow-lg p-4 mt-2 rounded-xl">
                                        <DescriptionSplit description={t('reservation.exclusivity.description')} />
                                        {/* <Link link="/infos" text={t('reservation.exclusivity.link')} textColor="main" /> */}
                                    </div>
                                </div>
                            }

                            <Hr />

                            <H5 title={t('reservation.details.accommodation')} underline="true" />
                            <div className="mt-1 flex justify-between">
                                <H6 title={t('reservation.details.pricePerNight') + ` (x${getNbDaysStay()})`} />
                                <H6 title={accommodationPrice + "€"} />
                            </div>

                            {discountLongAccommodation !== 0 &&
                                <div className="pl-6 mt-1 flex justify-between">
                                    <span className="text-sm">{t('reservation.details.discountLongAccommodation')}</span>
                                    <span className="text-sm">-{discountLongAccommodation}€</span>
                                </div>
                            }

                            <div className="pl-6 mt-1 flex justify-between">
                                <span className="text-sm">{t('reservation.details.dailyCleaningFees')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            <div className="mt-1 flex justify-between">
                                <H6 title={t('reservation.details.endOfStayCleaningFees')} />
                                <H6 title={getPriceEndOfStayCleaningFees() + "€"} />
                            </div>

                            <div className="mt-1 flex justify-between">
                                <H6 title={t('reservation.details.touristTax')} />
                                <H6 title={getPriceTouristTax() + "€"} />
                            </div>

                            <div className="flex mt-4 justify-between">
                                <H4 title={t('reservation.details.subtotal')} />
                                <span>{getSubTotalAccommodation()}€</span>
                            </div>

                            <Hr />

                            <H5 title={t('reservation.details.services')} underline="true" />

                            <div className="pl-6 mt-1 flex justify-between">
                                <span className="text-sm">{t('reservation.details.breakfast')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            <div className="pl-6 flex justify-between">
                                <span className="text-sm">{t('reservation.details.concierge')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            {servicesSelected.length > 0 &&
                                <>
                                    {servicesSelected.map((service, index) => (
                                        <div className="pl-6 flex justify-between" key={index}>
                                            <span className="text-sm">
                                                {service.quantity}x&nbsp;
                                                {service.name[i18n.language] || service.name.en}
                                            </span>
                                            {service.isPriceDefined &&
                                                <span className="text-sm">{getPriceService(service) === "0" ? `${t('reservation.services.free')}` : getPriceService(service) + "€"}</span>
                                            }
                                            {!service.isPriceDefined &&
                                                <span className="text-sm">{t('reservation.details.toDefine')}</span>
                                            }
                                        </div>
                                    ))}

                                    <div className="flex mt-4 justify-between">
                                        <H4 title={t('reservation.details.subtotal')} />
                                        <span>{getSubTotalServices()}€</span>
                                    </div>
                                </>
                            }

                            <div className="flex mt-4 justify-between">
                                <H3 title={t('reservation.details.total')} />
                                <span>{getTotal()}€</span>
                            </div>

                            {!displayFormContactDetails &&
                                <div className="mt-10 text-center w-full">
                                    <Button onClick={clickDisplayFormContactDetails} text={t('reservation.details.getQuotation')} className="w-full" />
                                </div>
                            }

                            {displayFormContactDetails &&
                                <>
                                    <Hr />
                                    <H4 title={t(t('reservation.details.descriptionContactForm'))} className="text-center mb-8" />
                                    <PersonnalInfoForm customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} fullWidth={true} />
                                    <Button onClick={sendQuotation} text={t('reservation.details.validate')} className="mt-8 w-full" />
                                </>
                            }

                            <div className="mt-4 text-xs text-center">
                                {t('reservation.details.contactFinalizeQuotation')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}