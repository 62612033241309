import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import FullWidthImage from '../../../../components/Images/image-full-width';
import H2 from '../../../../components/Titles/h2';
import DescriptionSplit from '../../../../components/Description/description-split';
import Link from '../../../../components/Links/link';
import Button from '../../../../components/Buttons/button';
import CarouselImages from '../../../../components/Carousel/carousel-images';
import useIntersectionObserver from '../../../../utils/hooks/intersection-observer';

const SectionDomainOverview = ({ image, listOfPaths }) => {
    const { t } = useTranslation();

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const buttonRef = useRef(null);
    const parentRef = useRef(null);
    const carouselContainerId = 'carousel-container';
    const textContainerId = 'text-container';

    const [carouselHeight, setCarouselHeight] = useState(0);

    useIntersectionObserver(
        [titleRef, descriptionRef, buttonRef],
        ["animate-fade-in-left", "animate-fade-in-up", "animate-fade-in-up"]
    );

    const getHeightImageVignes = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 576) {
            return 250;

        } else {
            return 400;
        }
    };

    function remToPixels(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    useEffect(() => {
        const updateHeight = (carousel, text) => {
            if (carousel && text) {
                const newCarouselHeight = carousel.clientHeight;
                const newTextHeight = text.clientHeight;
                const isMobile = window.innerWidth < 1024;

                let maxHeight;

                if (isMobile) {
                    maxHeight = newCarouselHeight + newTextHeight + remToPixels(9);

                } else {
                    maxHeight = newCarouselHeight + (getHeightImageVignes() / 2) > newTextHeight + getHeightImageVignes()
                        ? newCarouselHeight + (getHeightImageVignes() / 2)
                        : newTextHeight + getHeightImageVignes();
                }

                setCarouselHeight(maxHeight);
            }
        };

        const carouselElement = document.getElementById(carouselContainerId);
        const textElement = document.getElementById(textContainerId);

        const handleImageLoad = () => {
            updateHeight(carouselElement, textElement);
        };

        const images = carouselElement?.getElementsByTagName('img') || [];
        for (let img of images) {
            if (img.complete) {
                handleImageLoad();
            } else {
                img.addEventListener('load', handleImageLoad);
            }
        }

        updateHeight(carouselElement, textElement);

        const resizeObserver = new ResizeObserver(() => {
            updateHeight(carouselElement, textElement);
        });

        if (carouselElement) {
            resizeObserver.observe(carouselElement);
        }

        return () => {
            if (carouselElement) {
                resizeObserver.unobserve(carouselElement);
                for (let img of images) {
                    img.removeEventListener('load', handleImageLoad);
                }
            }
        };
    }, []);

    return (
        <div className="mt-12 lg:mt-24 relative" ref={parentRef} style={{ height: carouselHeight, minHeight: "500px" }}>
            <FullWidthImage url={image} alt={t('home.sectionDomainOverview.photos.altVignes')} customHeight={getHeightImageVignes()} divClassName='relative h-fit' />
            <div className="flex flex-col-reverse lg:flex-row">
                <div id={textContainerId} className="lg:w-1/2 -mt-40 lg:mt-0 p-6 lg:pl-24 lg:pt-12 lg:pr-12 text-center lg:text-left">
                    <div ref={titleRef} className="opacity-0 mb-6">
                        <H2 title={t('home.sectionDomainOverview.title')} />
                    </div>
                    <div ref={descriptionRef} className="opacity-0 mb-8">
                        <DescriptionSplit description={t('home.sectionDomainOverview.description')} />
                    </div>
                    <div ref={buttonRef} className="opacity-0">
                        <Link link="/domain" disableHoverEffect={true}>
                            <Button text={t('home.sectionDomainOverview.button')} />
                        </Link>
                    </div>
                </div>

                <div id={carouselContainerId} className="w-10/12 lg:w-1/2 relative lg:absolute left-[16.666%] lg:left-1/2 lg:right-0 -top-36 lg:top-48">
                    <CarouselImages imagesPaths={listOfPaths.sectionDomainOverview} verticalAlign="right" horizontalAlign="bottom" />
                </div>
            </div>
        </div>
    );
};

export default SectionDomainOverview;